<template>
  <section class="section_start">
    <div class="content_main">
      <div class="wrap_bg" :class="{ mo: $customDevice.isMobile }">
        <div class="txt_make font_maple_story">
          <!-- eslint-disable-next-line -->
          <em>헬로메이플</em>
          과 함께
          <br />
          마음껏 상상하고 만들어요!
        </div>
        <div class="txt_make_desc">
          메이플 친구들과 재미있게 코딩을 배울 수 있어요.
          <br />
          상상했던 것을 만들고 자유롭게 표현해보세요!
        </div>

        <atoms-border-button
          v-if="$customDevice.isMobile"
          v-log-a2s-click
          data-a2s-obj-name="Main_Start"
          append-icon="chevron_right_rouned"
          @click="doDownloadClient"
        >
          헬로메이플 시작하기
        </atoms-border-button>
        <div v-else class="wrap_btn">
          <atoms-border-button
            append-icon="chevron_right_rouned"
            @click="checkWebPlayPossible(() => router.push('/web-play'))"
            @mouseenter="
              ($event) =>
                showTooltip({
                  position: 'right',
                  event: $event,
                  content:
                    '다양한 디바이스에서 웹으로<br/>헬로메이플을 체험해 보세요'
                })
            "
            @mouseleave="hideTooltip"
          >
            웹에서 실행하기
          </atoms-border-button>
          <br />
          <atoms-border-button
            v-log-a2s-click
            data-a2s-obj-name="Main_Start"
            append-icon="chevron_right_rouned"
            color-pack="purple"
            @mouseenter="
              ($event) =>
                showTooltip({
                  position: 'right',
                  event: $event,
                  content:
                    '윈도우 컴퓨터라면, PC앱을 통해서<br/>헬로메이플에 빠르게 접속할 수 있어요'
                })
            "
            @mouseleave="hideTooltip"
            @click="downloadClient"
          >
            PC앱 설치하기
          </atoms-border-button>
        </div>
        <atoms-c-image class="bg_yeti" file-name="main-yeti" :density="2" />
        <atoms-c-image class="bg_pink" file-name="main-pink" :density="2" />
        <atoms-c-image
          class="bg_mushroom"
          file-name="main-mushroom"
          :density="2"
        />
      </div>
    </div>

    <template-main-guide-modal
      v-if="isOpenGuide"
      @close="isOpenGuide = false"
    />
  </section>
</template>

<script setup lang="ts">
import { doDownloadClient, checkWebPlayPossible } from 'assets/js/utils'
const { $customDevice } = useNuxtApp()
const router = useRouter()
const { showTooltip, hideTooltip } = useTooltip()
const isOpenGuide = ref(false)
const downloadClient = () => {
  doDownloadClient(() => {
    isOpenGuide.value = true
  })
}
</script>
<style lang="scss" scoped>
.section_start {
  @include imageSet('@/assets/images/bg-main-start');
  background-color: #b8f2ff;
  background-position-x: center;
  padding-top: 220px;
  padding-bottom: 93px;
  .wrap_bg {
    width: 936px;
    padding-bottom: 129px;
    position: relative;
    &.mo {
      padding-bottom: 217px;
    }
    .bg_yeti {
      position: absolute;
      left: 78px;
      bottom: 48.5px;
    }
    .bg_pink {
      position: absolute;
      right: 187px;
      bottom: 5px;
    }
    .bg_mushroom {
      position: absolute;
      right: 76px;
      bottom: 47px;
    }
    .wrap_btn .btn_border {
      width: 220px;
    }
  }
  .txt_make {
    text-align: center;
    font-size: 52px;
    line-height: 1.4;
    letter-spacing: -2.08px;
    em {
      color: $blue1;
    }
  }
  .txt_make_desc {
    padding-top: 16px;
    padding-bottom: 40px;
    color: $black1;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.8px;
  }
}

@media (max-width: 999px) {
  .section_start {
    @include imageSet('@/assets/images/bg-main-start-mo');
    padding-top: 124px;
    padding-bottom: 101px;
    background-position-y: bottom;
    background-color: #c2f4ff;
    .wrap_bg {
      width: 360px;
      padding-bottom: 181px;
      .bg_yeti {
        width: 140.89px;
        height: 170px;
        left: 7px;
        bottom: 33.5px;
      }
      .bg_pink {
        width: 92px;
        height: 138px;
        right: 56px;
        bottom: -1px;
      }
      .bg_mushroom {
        width: 69.47px;
        height: 70px;
        right: 3px;
        bottom: -1px;
      }
      .wrap_btn .btn_border {
        width: 181px;
      }
    }
    .txt_make {
      font-size: 32px;
      line-height: 1.4;
      letter-spacing: -1.28px;
      height: 90px;
    }
    .txt_make_desc {
      padding-top: 8px;
      padding-bottom: 16px;
      font-size: 16px;
      letter-spacing: -0.64px;
    }
  }
}
</style>
